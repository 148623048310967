export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL'; 

export const MY_ORDERS_REQUEST = "MY_ORDERS_REQUEST" 
export const MY_ORDERS_SUCCESS = "MY_ORDERS_SUCCESS" 
export const MY_ORDERS_FAIL = "MY_ORDERS_FAIL" 


export const ALL_ORDERS_REQUEST = "ALL_ORDERS_REQUEST" 
export const ALL_ORDERS_SUCCESS = "ALL_ORDERS_SUCCESS" 
export const ALL_ORDERS_FAIL = "ALL_ORDERS_FAIL" 


export const UPDATE_ORDERS_REQUEST = "UPDATE_ORDERS_REQUEST" 
export const UPDATE_ORDERS_SUCCESS = "UPDATE_ORDERS_SUCCESS" 
export const UPDATE_ORDERS_FAIL = "UPDATE_ORDERS_FAIL" 
export const UPDATE_ORDERS_RESET = 'UPDATE_ORDERS_RESET'

export const DELETE_ORDERS_REQUEST = "DELETE_ORDERS_REQUEST" 
export const DELETE_ORDERS_SUCCESS = "DELETE_ORDERS_SUCCESS" 
export const DELETE_ORDERS_FAIL = "DELETE_ORDERS_FAIL" 
export const DELETE_ORDERS_RESET = 'DELETE_ORDERS_RESET'


export const ORDER_DETAIL_REQUEST = "ORDER_DETAIL_REQUEST" 
export const ORDER_DETAIL_SUCCESS = "ORDER_DETAIL_SUCCESS" 
export const ORDER_DETAIL_FAIL = "ORDER_DETAIL_FAIL" 


export const CLEAR_ERRORS = 'CLEAR_ERRORS';