import React, { Fragment } from 'react'
import '../../App.css'
import Logo from '../../components/layout/Header/logo1.png'
const Loader = () => {
  return (
    <Fragment>

    <div className='loader'>
      {/* <img src={Logo} className='loader' /> */}
    </div>
    </Fragment>
  )
}

export default Loader
