import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './Clock.css'
const Clock = () => {
    const [days,setDays]= useState();
    const [hours,setHours]= useState();
    const [minutes,setMinutes]= useState();
    const [seconds,setSeconds]= useState();

    let interval;
    const countDown =()=>{
      const dest = new Date('April 31,2024');
      interval = setInterval(()=>{
        const now = new Date().getTime();
        const diff = dest-now;
        const days = Math.floor(diff/(1000*60*60*24));
        const hours = Math.floor(diff%(1000*60*60*24)/(1000*60*60));
        const minutes = Math.floor(diff%(1000*60*60)/(1000*60));
        const seconds = Math.floor(diff%(1000*60)/(1000));
        if(dest<0) clearInterval(interval.current);
        else{
          setDays(days);
          setHours(hours);
          setMinutes(minutes);
          setSeconds(seconds);

        }
      })
    }
    useEffect(()=>{
      countDown();
    })
  return (
    <div className='clock__wrapper d-flex align-item-center gap-3 mb-3 ml-5'>
      <div className='clock__data d-flex align-item-center gap-3'>
        <div className='text-center'>
          <h1 className='text-white fs-3 mb-2'>{days}</h1>
          <h5 className='text-white fs-6'>Days</h5>
        </div>
        <span className='text-white fs-3'>:</span>
      </div>
      <div className='clock__data d-flex align-item-center gap-3'>
        <div className='text-center'>
          <h1 className='text-white fs-3 mb-2'>{hours} </h1>
          <h5 className='text-white fs-6'>Hours</h5>
        </div>
        <span className='text-white fs-3'>:</span>
      </div>
      <div className='clock__data d-flex align-item-center gap-3'>
        <div className='text-center'>
          <h1 className='text-white fs-3 mb-2'>{minutes} </h1>
          <h5 className='text-white fs-6'>Minutes</h5>
        </div>
        <span className='text-white fs-3'>:</span>
      </div>
      <div className='clock__data d-flex align-item-center gap-3'>
        <div className='text-center'>
          <h1 className='text-white fs-3 mb-2'>{seconds} </h1>
          <h5 className='text-white fs-6'>Seconds</h5>
        </div>
        
      </div>
    </div>
  )
}

export default Clock
