import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../../components/layout/Loader'
import MetaData from '../layout/MetaData'
import { useDispatch, useSelector } from 'react-redux'
import {MDBDataTable} from 'mdbreact'
import {useAlert} from 'react-alert'
import { myOrders } from '../../actions/orderAction'
const ListOrder = () => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const{loading, error, orders} = useSelector(state=>state.myOrders);
    console.log(orders)
    useEffect(()=>{
        dispatch(myOrders())
        if(error){
            alert.error(error);
        }

    },[dispatch,error,alert])
    const setOrders=()=>{
        const data = {
          columns:[
            {
              label:'Order ID',
              field :'id',
              sort :'asc'
            },
            {
              label:'Num of Items',
              field :'numOfItems',
              sort :'asc'
            },
            {
              label:'Amount',
              field :'amount',
              sort :'asc'
            },
            {
              label:'Status',
              field :'status',
              sort :'asc'
            },
            {
              label:'Actions',
              field :'actions',
              sort :'asc'
            }
          ],
          rows:[]
        }
        orders.forEach(order=>{
            data.rows.push({
              id: order._id,
              numOfItems: order.orderItems.length,
              amount : `Rs. ${order.totalPrice}`,
              status :order.orderStatus && String(order.orderStatus).includes('Delivered')?
              <p style={{color:'green'}}>{order.orderStatus}</p>:
              <p style={{color:'red'}}>{order.orderStatus}</p>,
              actions:
              <Link to={`/order/${order._id}`} className="btn btn-primary">
                <i className='fa fa-eye'>
                </i>
              </Link>
            })
          })
          return data
        }
  return (
    <Fragment>
    <MetaData title={'My Orders'} />

    <h1 className='mt-5 ml-5 mb-3'>My Orders</h1>

    {loading ? <Loader />:(
      <MDBDataTable
      data={setOrders()}
      className='px-5'
      bordered
      striped
      hover
      responsive small
      
      />
     )} 
  </Fragment>
  )
}

export default ListOrder
